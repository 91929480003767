import React, { useEffect, useContext }  from 'react'
import Login from '../../Auth/Login/Login'
import Layout from '../../components/layout'
import { gsap } from "gsap";
import { Link } from 'gatsby';
import { AuthContext } from '../../firebase'
import {navigate } from 'gatsby'
import Seo from '../../components/seo'

const SignIn = () => {

  const userInit = {myUser: ""}
  const { authUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit

  useEffect(() => {
    user && navigate("/app/dashboard")
  }, [])

  let content = null;

  useEffect(() => {
    
      gsap.from(
        content.children,
        0.35,
        { y: 15, opacity: 0, stagger: 0.1 },
        "-=0.15"
      )
  }, [content]);
  return (
    <Layout>
      <Seo title="T&eacute;l&eacute;m&eacute;decine - Consultation en ligne - Haiti "  />
      <div className="sign-contain">
        <div className="sign-element mycard">
          <h5 className="center color-1">Vous &ecirc;tes m&eacute;decin ou professionel m&eacute;dical?</h5>
          <h3 className="center color-4">Connectez-vous</h3>
          <Login ref={e => (content = e)} isDoctor={true} />
          <p className="sign-alt color-1 center">Vous avez oubli&eacute; votre mot de passe? <Link className="sign-alt-link" to="/resetpassword">Changer votre mot de passe ici!</Link></p>
          <p className="sign-alt color-1 center">Vous n'avez pas encore de compte? </p>
          <p className="sign-alt color-1 center">Si vous &ecirc;tes m&eacute;decin, <Link className="sign-alt-link" to="/doctor/signup">enregistrez-vous ici!</Link></p>
          <p className="sign-alt color-1 center">Si vous &ecirc;tes un autre professionel de sant&eacute;, <Link className="sign-alt-link" to="/doctor/signupmedpro">enregistrez-vous ici!</Link></p>
        </div>
      </div>
    </Layout>
  )
}

export default SignIn